<template>
  <v-container>
    <v-row justify="center">
      <v-col
        xl="6"
        lg="8"
        class="py-0"
      >
        <v-card
          outlined
          class="hs-rounded-lg"
        >
          <v-container>
            <v-fade-transition hide-on-leave>
              <v-row
                v-if="chooseRead"
                justify="center"
              >
                <v-btn
                  text
                  color="primary"
                  @click="chooseRead = !chooseRead"
                >
                  {{ $t('goBack') }}
                </v-btn>
              </v-row>
            </v-fade-transition>
          
            <v-fade-transition hide-on-leave>
              <v-row
                v-if="!chooseRead"
                class="px-4"
              >
                <v-btn
                  text
                  color="primary"
                  style="border-radius: 12px;"
                  @click="allRead"
                >
                  {{ $t('notifications.setAllRead') }}
                  <v-icon right>
                    {{ mdiCheck }}
                  </v-icon>
                </v-btn>

                <v-spacer />

                <v-btn
                  text
                  color="primary"
                  style="border-radius: 12px;"
                  @click="chooseRead = !chooseRead"
                >
                  {{ $t('notifications.setNotRead') }}
                  <v-icon right>
                    {{ mdiBellOutline }}
                  </v-icon>
                </v-btn>
              </v-row>
            </v-fade-transition>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col
        xl="6"
        lg="8"
      > 
        <v-list
          two-line
          class="py-0 hs-rounded-list"
          color="transparent"
        >
          <v-list-item-group
            multiple
            v-model="notRead"
            active-class="primary--text text-darken-1"
          >
            <v-card
              v-for="(notification, idx) in formatNotifications"
              :key="`notification_${idx}`"
              class="mb-2 hs-rounded-lg"
              outlined
            >
              <v-tooltip
                left
                :disabled="!chooseRead"
              >
                <template v-slot:activator="{ on }">                    
                  <v-list-item
                    v-on="on"
                    @click="read(notification)"
                  >
                    <template v-slot:default="{ active, }">
                      <v-list-item-avatar>
                        <v-img
                          v-if="notification.thumbnail.url"
                          :src="notification.thumbnail.url"
                        />
                        <v-icon
                          v-else
                          x-large
                        >
                          {{ mdiAccountCircle }}
                        </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title v-html="formatTitle(notification)" />
                        <v-list-item-subtitle v-html="formatText(notification)" />
                      </v-list-item-content>
                      
                      <v-list-item-action>
                        <v-checkbox
                          v-if="chooseRead"
                          :input-value="active"
                          color="primary"
                          :on-icon="mdiMinusBox"
                        />
                        <div
                          v-else
                          :class="`body-2 grey--text ${dateColor}`"
                          v-text="notification.date"
                        />
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </template>
                
                <div v-text="setAs(notification.read)" />
              </v-tooltip>
            </v-card>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { formatDate, formatNotification } from '@utils'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import { enUS, ptBR } from 'date-fns/locale'

import {
  mdiCheck,
  mdiBellOutline,
  mdiAccountCircle,
  mdiMinusBox
} from '@mdi/js'

export default {
  data () {
    return {
      mdiCheck,
      mdiBellOutline,
      mdiAccountCircle,
      mdiMinusBox,
      
      chooseRead: false
    }
  },

  computed: {
    ...mapGetters({
      profile: 'profile/id',
      notifications: 'notification/notifications'
    }),

    dateColor() {
      return !this.$vuetify.theme.dark
        ? 'text--darken-2'
        : ''
    },

    formatNotifications() {
      const locale = this.$i18n.locale === 'en'
        ? enUS
        : ptBR

      return this.notifications.length > 0
        ? this.notifications.map(notification => {
            const date = formatDate(notification.createdAt, this.$i18n.locale)
            return {
              date,
              ...notification
            }
          })
          
        : [{
            thumbnail: {
              url: ''
            },
            type: 'default',
            names: [],
            date: formatDistanceToNow(new Date(), { locale })
          }]
    },

    notRead: {
      get () {
        let notRead = []
        this.notifications.forEach((notification, idx) => {
          if (!notification.read && !notification.divider)
            notRead.push(idx)
        })

        return notRead
      },
      set () {}
    }
  },

  methods: {
    ...mapMutations({
      setRead: 'notification/toggleRead',
      setAllRead: 'notification/allRead'
    }),

    formatTitle(notification) {
      return formatNotification(notification, this, true)
    },

    formatText(notification) {
      return formatNotification(notification, this, false)
    },

    setAs(read) {
      const readCode = read
        ? 'setAsRead'
        : 'setAsUnread'

      return this.$t(`notifications.${readCode}`)
    },

    read(notification) {
      const notificationRead = this.chooseRead
        ? !notification.read
        : true

      if (this.chooseRead || !notification.read) {
        this.$socket.notification.emit('setRead', {
          notificationId: notification.id,
          read: notificationRead
        })
        this.setRead({id: notification.id})
      }

      if (notification.to && !this.chooseRead)
        this.$router.push(notification.to)
    },

    allRead () {
      const profile = this.profile
      this.$socket.notification.emit('allRead', {profile})
      this.setAllRead()
    }
  }
}
</script>

<style>
.hs-rounded-list .v-list-item--active::before {
  border-radius: 9px !important;
}
</style>